@import "../../custom";

.rankingCard {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: 0 0 2px #9ba0a7;
  overflow: hidden;


  table {
    .shimmer-title-line {
      height: 10px !important;
    }

    tr {
      height: 50px;

      &:nth-of-type(even) {
        background-color: $lightGreen;
      }
    }

    td {
      background: transparent;
      color: #000;
      font-family: Archivo, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 15.23px;
      letter-spacing: 0.05em;
      text-align: left;
      vertical-align: middle;


      img {
        border: solid 1px #000;
        border-radius: 50%;
      }

      .flag {
        font-size: 2em !important;
      }
    }
  }

  .rankingTabs {
    border-color: transparent;
    background: $lightGreen;

    .nav-link {
      border: 0 transparent;
      color: $darkGreen;

      &:hover, &:focus {
        border: 0 transparent;
      }

      &.active {
        border: 0px;
        border-bottom: solid 1px $border-color;
        background: $darkGreen;
        color: #FFF;
        font-weight: 700;
      }
    }
  }
}
