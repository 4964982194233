
#loginContainer{
   h2{
     font-family: Archivo, serif;
     font-size: 20px;
     font-weight: 900;
     line-height: 26px;
     letter-spacing: 0.05em;
     text-align: left;

     span{
       color: #1160D6;
     }
   }

  h4{
    font-weight: 900;
    font-size: 18px;
  }

  .brandButton{
    background: #A1DE29;
    border-radius: 5rem;
    color: #000;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-family: Archivo, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
    letter-spacing: 0.04em;
  }

  .phoneNumber{
    border: 1px solid #D1D1D1;
    padding: 11px 19px 11px 10px;
    gap: 10px;
    border-radius: 6px;
    color: #000;
    max-width: 250px;
    margin: 0 auto;
    font-size: 20px;
    text-align: center;
    letter-spacing: 1px;
  }

}