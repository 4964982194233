@import "../../common";

.footer{
  background-color: $primary;
  color: #FFF;

  .nav-link{
    color: #FFF !important;
    font-family: Archivo, serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 15.6px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
  }
}
