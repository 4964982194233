@import './custom';


.App {
  text-align: center;
}

.seeAll{
  margin-top: 44px;
  text-decoration: none;
  color: $link;
  font-size: 12px;
}

