@import "../../custom";

.rankingPageTabs{
  border-color: transparent;
  background: $lightBlue;

  &.round{
    box-shadow: none;
    border-radius: 5rem;
    
    .nav-link {
      border-radius: 5rem;
    }
  }

  .nav-link {
    border: 0 transparent;
    color: $link;
    font-size: 14px;

    &:hover, &:focus {
      border: 0 transparent;
    }

    &.active {
      border: 0px;
      border-bottom: solid 1px $border-color;
      background: $primary !important;
      color: #FFF !important;
      font-weight: 700;
    }
  }
}
.rankingPage{

  .tab-content{
    padding: 0 !important;
    margin: 0 !important;
  }

  table {
    .shimmer-title-line {
      height: 10px !important;
    }

    tr {
      height: 50px;

      &:nth-of-type(even) {
        background-color: $lightGreen;
      }
    }

    td {
      background: transparent;
      color: #000;
      font-family: Archivo, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 15.23px;
      letter-spacing: 0.05em;
      text-align: left;
      vertical-align: middle;


      img {
        border: solid 1px #000;
        border-radius: 50%;
      }

      .flag {
        font-size: 2em !important;
      }
    }
  }
}