@import "../../custom";


#calendarDropDown{
  svg{
    path{
      fill: #FFF !important;
    }
  }
}
.dateFilterBtn{
  background: $primary;
  svg{
    path{
      fill: #FFF !important;
    }
  }
}
h6{
  color: #000;
}

.ReactModalPortal{
  .dateSlidePanel{
    width: 90% !important;
    max-width: 480px;
    margin: 0 auto;

    .slide-pane__content{
      padding: 0;
    }

    .slide-pane__header{
      background-color: $lightBlue;
      display: none;
      .slide-pane__title{
        font-family: Archivo, serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-align: left;
        color: #000;
      }
    }
  }
  .slide-pane_from_bottom{
    height: 90vh;
    margin-top: 10vh;
  }

  .slideContent{
    height: 90vh;
    overflow: auto;

    .dateFilter{
      width: 100%;
      height: 40px;
      padding: 10px;
      font-family: Archivo, serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 15.6px;
      letter-spacing: 0.05em;
      text-align: left;
      cursor: pointer;

      &.selected{
        background: #1160D6;
        color: #FFF;
      }

      &:hover{
        background: #1160D6;
        color: #FFF;
      }

    }
  }
}