@import "../../common";

.playerCard{
  background: linear-gradient(89.92deg, #C3EE68 1.26%, #1F5D4E 99.93%);
  border: 1px solid $yellow;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;

  .playerImage{
    position: relative;
    width: 30%;
    transition: width 2s;
  }
  .playerInfo{
    width: 70%;
    display: flex;
    flex-direction: column;
    color: #FFF;

    .ranking{
      font-family: Archivo, serif;
      font-size: 20px;
      font-weight: 900;
      line-height: 26px;
      letter-spacing: 0.05em;
      text-align: left;

      svg{
        path{
          fill: #A1DE29;
        }
      }
    }

    .country{
      text-transform: uppercase;
      font-family: Archivo, serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 10.88px;
      letter-spacing: 0.02em;
      text-align: left;

      .flag{
        font-size: 2em !important;
      }

    }

    .name{
      font-family: Archivo, serif;
      font-size: 20px;
      font-weight: 900;
      line-height: 26px;
      letter-spacing: 0.05em;
      text-align: left;
    }
    .career{
      font-family: Archivo, serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 10.88px;
      letter-spacing: 0.02em;
      text-align: center;
    }
    .quickStats{
      display: flex;
      flex-direction: row;
      margin-top: .5rem;
      margin-bottom: .5rem;

      .stat{
        background: #FFFFFF1A;
        border-radius: 1rem;
        width: 32%;
        text-align: center;
        min-height: 50px;
        margin-right: 1%;
        padding: .5rem 0;

        h2{
          margin-bottom: 0;
          font-family: Archivo, serif;
          font-size: 12px;
          font-weight: 700;
          line-height: 20.8px;
          letter-spacing: 0.04em;
        }
        span{
          font-family: Archivo, serif;
          font-size: 10px;
          font-weight: 500;
          line-height: 10.88px;
          letter-spacing: 0.02em;
        }
      }
    }
  }
}

.ReactModalPortal{
  .playerSlidePanel{
    width: 100% !important;
    max-width: 680px;
    margin: 0 auto;


    .pageHeader{
      background: linear-gradient(89.92deg, #1F5D4E 0.06%, #C3EE68 98.73%);
      color: #FFF;

      svg{
        path{
          fill: #FFF !important;
        }
      }
    }
    .playerCardImageWrapper{
      min-height: 270px;
      text-align: end;
      @media (max-width: 1024px) {
        min-height: 270px;
      }
    }
    .playerHeader{
      position: relative;
      margin-top: .1rem;
      background: linear-gradient(89.92deg, #1F5D4E 0.06%, #C3EE68 98.73%);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #FFF;

      > div{
        width: 50%;
      }

      h2{
        font-family: Archivo, serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-align: left;
      }
      p{
        font-family: Archivo, serif;
        font-size: 10px;
        font-weight: 600;
        line-height: 10.88px;
        letter-spacing: 0.05em;
        text-align: left;
      }

      .playerImage{
        min-height: 225px;
        object-fit: contain;
      }

      .playerStat{
        position: absolute;
        bottom: .5rem;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        >div {
          width: 24%;
          border: solid 1px #FFF;
          margin-right: 1%;
          border-radius: 1rem;
          background-color: rgba(0, 0, 0, 0.2);
          height: 65px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h4{
            font-family: Archivo, serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 20.8px;
            letter-spacing: 0.04em;
            margin: 0;
          }
          p{
            font-family: Archivo, serif;
            font-size: 10px;
            font-weight: 500;
            line-height: 10.88px;
            letter-spacing: 0.02em;
            text-align: center;
            margin: 0;
          }
        }
      }
    }

    .playerOtherInfo{
      .playerInfo{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid #AFAFAF;
        text-transform: capitalize;

        .title{
          font-family: Archivo, serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 13.06px;
          letter-spacing: 0.05em;
          text-align: left;
          color: #AFAFAF;
        }
        .value{
          font-family: Archivo, serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 13.06px;
          letter-spacing: 0.05em;
          text-align: right;
          color: #000
        }
      }
    }

    .slide-pane__content{
      padding: 0;
    }

    .slide-pane__header{
      display: none;
    }
  }
  .slide-pane_from_bottom{
    height: 92vh;
    margin-top: 8vh;
  }
}