@import "../../common";

.oneEvent{
  background-color: transparent;
  color: $primary;
  border: 1px solid #1160D6;
  margin: .5rem;
  width: 95% !important;
  
  .eventDates{
    font-family: Archivo, serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 10.88px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }

  .eventTitle{
    font-family: Archivo, serif;
    font-size: 16px;
    font-weight: 900;
    line-height: 17.41px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #000;
  }
  .eventSubTitle{
    margin-top: .2rem;
    font-family: Archivo, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.06px;
    letter-spacing: 0.05em;
    text-align: left;
    color:#1160D6;
    text-transform: uppercase;
  }

  h5, p{
    margin-bottom: 0;
  }

  .nav-item{
    font-size: 12px;
    .nav-link.active{
      background: transparent;
      border-bottom: solid 1px $primary;
      color: $primary;
      border-radius: 0;
    }
  }

  .match{
    font-size: 12px;
    margin: 0;

  }
  .liveWrapper{
    position: relative;
    width: 15px;
  }
  .duration{
    font-size: 12px;
    color: #7a7a7a;
  }

  .matchScores{
    font-size: 10px;
    line-height: 22px;
  }

  .playerName{
    font-size: 12px;
    line-height: 10px;
  }

}

.live {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
    border-radius: 45px;
    background-color: red;
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: red;
    border-radius: 50px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}


.HardSurface{
  background: $primary;
}
.GrassSurface{
  background: $darkGreen;
}
.ClaySurface{
  background: $orange;
}