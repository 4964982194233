.profile{
  border: solid 1px #9ba0a7;
  border-radius: .3rem;
  overflow: hidden;
  .upperProfile{
    display: flex;
    flex-direction: row;
    height: 150px;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #051224 0, #232e3e 85.42%);
    border-color: #9ba0a7;

    h3{
      color: white;
      text-align: center;
      padding: 1rem;
    }

    img{
      height: 100px;
      width: 45%;
      object-fit: contain;
    }
  }
}