@import "../../common";

.matchCard{
  border: 1px solid #1160D6;
  border-radius: 1rem;
  font-family: Archivo, serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: left;
  line-height: 20px;
  width: 48%;
  margin: 1%;
  display: inline-block;
  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }

  .matchRow{
    background-color: $lightBlue;
    &.winner{
      border: 1px solid $yellow;
      background-color: #F0F9F5;
    }
  }

  .duration{
    margin-bottom: 0;
  }

  .matchActions{
    display: flex;
    flex-direction: row;
    align-items: self-end;
    justify-content: flex-end;

    button{
      border-radius: 1rem;
      font-family: Archivo, serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 15.6px;
      letter-spacing: 0.05em;
      text-align: left;
      margin-left: .1rem;
    }
  }
  .singleScore{
    //span {
    //  border: solid 1px #1160D6;
    //  padding: .05rem .3rem;
    //  border-radius: .2rem;
    //}
  }

}
.ReactModalPortal{
  .matchSlidePanel{
    width: 100% !important;
    max-width: 680px;
    margin: 0 auto;

    .slide-pane__content{
      padding: 0;
    }

    .slide-pane__header{
      background-color: $lightBlue;
      display: none;
      .slide-pane__title{
        font-family: Archivo, serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-align: left;
        color: #000;
      }
    }
  }
  .slide-pane_from_bottom{
    height: 92vh;
    margin-top: 8vh;
  }
}