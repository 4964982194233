
$primary: #1160D6;
$yellow: #A1DE29;
$darkBlue: #184890;
$blue: #235299;
$lightBlue: #D9EFFF;
$lightGreen: #E9F1EF;
$darkGreen: #25705C;
$orange:#F29057;






$border-color: #9ba0a7;
$secondary: #f5f6f6;
$white: #FFF;
$dark-gray: #212529;
$link: #121fff;



.bg-primary{
  background-color: $primary !important;
}
.btn-close{
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e);
}
.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1176px;
}


.atpNavBar {
  background: $primary !important;

  .navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28161,222,41,1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%273%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  }
}

.offcanvas {
  --bs-offcanvas-width: 300px !important;
  background-color: $darkBlue !important;

  .navbar-nav{
    align-items: center;
  }

  .offcanvas-body{
    padding-right: 0;
  }

  .nav-item {
    margin: .3rem 0rem;
    font-family: Archivo, serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
    padding: .5rem;
    border-radius: 1rem 0 0 1rem;
    line-height: 11px;

    @media (max-width: 1024px) {
      line-height: 20.8px;
      align-items: start;
      width: 100%;
    }

    a{
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
    }

    span{
      margin-left: .5rem;
      @media (max-width: 1024px) {
        margin-left: 1rem;
      }
    }

    svg{
      width: auto;
      height: 14px;
      object-fit: contain;
      path{
        fill: #FFF;
      }
    }

    &.active{
      background-color: $primary;
      color: $yellow;

      svg{
        path{
          fill: $yellow;
        }
      }
    }

    a{
      color: inherit;
    }
  }
}

.homePageTitle{
  position: relative;
  font-family: 'Archivo', serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 19.58px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  margin: 15px 0;
  display: flex;
  align-items: flex-end;

  svg{
    margin-right: .5rem;
    path{
      fill: $primary;
    }
  }
}

.viewMore{
  font-family: Archivo, serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.6px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}

.pageHeader{
  background-color: $lightBlue !important;
  color: #000;
  display: flex;
  align-items: center;
  min-height: 65px;
  justify-content: space-between;

  .leftSection{
    display: flex;
    align-items: center;
  }

  h2{
    font-family: Archivo, serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 0;
  }

  svg{
    margin-right: .5rem;
    path{
      fill: $primary !important;
    }
  }

  .leftSection{

  }

  .scoresFilter{

    .dropdown-menu{
      background: #1060d6;
      .dropdown-item{
        color: #FFF !important;

        &:hover, &:active, &:focus {
          color: #1060d6 !important;
          background: #FFF !important;
        }
      }
    }
  }

  #calendarDropDown{
    font-family: Archivo, serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 15.6px;
    letter-spacing: 0.05em;
    text-align: left;
    border-radius: 5rem;
  }

}

.languageDropDown, .profileDropDown{
  .dropdown-menu{
    position: absolute;
    background: #FFF;
    border: 1px solid #1060d6;
    .dropdown-item{
      color: #1060d6 !important;

      svg{
        margin-right: .3rem;
        path{
          fill: #1060d6 !important;
        }
      }

      &:hover, &:active, &:focus {
        color: #FFF !important;
        background: #1060d6 !important;
        svg{
          path{
            fill: #FFF !important;
          }
        }
      }
    }
  }

}