@import "../../common";

#staticPageBody{
  line-height: 2rem;
  max-width: 1024px;

  h2{
    background-color: $lightBlue !important;
    color: #000;
    display: flex;
    align-items: center;
    min-height: 65px;
    justify-content: space-between;
    padding: 1rem;
    font-family: Archivo, serif;
    font-size: 16px;
    font-weight: 900;
    line-height: 17.41px;
    letter-spacing: 0.05em;
    text-align: center;
  }

  p{
    font-family: Archivo, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.05em;
    text-align: left;
    padding: 2rem;
  }

}