@import "../../custom";

.bracketsCard {
  background-color: transparent !important;
  border: 0 !important;
  overflow: hidden;
  box-shadow: none;

  .bracketsTabs {
    border-color: transparent;
    background: $lightBlue;
    padding-right: 0;
    border-radius: 5rem;
    @media(max-width: 1024px){
      border-radius: 1rem;
      background: transparent;
    }

    .nav-link {
      border: 0 transparent;
      color: $link;
      font-size: 14px;
      border-radius: 5rem;

      @media(max-width: 1024px){
        border-radius: 1rem;
        background: $lightBlue;
        margin-bottom: 0.2rem;
      }

      &:hover, &:focus {
        border: 0 transparent;
      }

      &.active {
        border: 0px;
        border-bottom: solid 1px $border-color;
        background: $primary;
        color: #FFF;
        font-weight: 700;
      }
    }
  }
  .iAlsAy{
    background: #FFF !important;
    border: 1px solid #AFAFAF;
    border-radius: 1rem;
    overflow: hidden;
    p{
      min-height: 0rem;
      margin: 0;
    }
  }
  .gynpwX{
    background: #FFF;
  }
  .dMGxZs{
    padding: 0.2rem;
  }
  .hLklXM, .htbwXn{
    width: 40% !important;
    justify-content: end;
  }

  .tab-pane{
    > div:first-child{
      > svg:first-child{
        > rect:first-child, > g:first-child > rect:first-child {
          stroke: #FFFFFF;
        }
      }
    }
  }
  g {
    g {
      rect {
        fill: #FFF !important;
        stroke: #1160D6;
      }
    }
  }

}


