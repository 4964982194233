@import "../../common";

a{
  text-decoration: none !important;
}
#mediaPage {
  .mediaWrapper {
    min-height: 615px;

    @media (max-width: 1024px){
      min-height: 225px;
    }
  }
}

#latestVideos{
  .mediaWrapper {
    min-height: 415px;

    @media (max-width: 1024px){
      min-height: 225px;
    }
  }
}
.articleCard{
  margin-bottom: .1rem;
  height: 100% !important;
  border-radius: 1rem;
  overflow: hidden;

  .card-body{
    background-color: #000;
    border-radius: 1rem;
    margin-top: -1rem;
    min-height: 135px;
  }

  .mediaWrapper{
    background: #D9EFFF;
  }

  &.small{

    .mediaWrapper{
      min-height: 135px !important;

      @media (max-width: 1024px){
        min-height: 103px !important;
      }
    }
    height: 225px !important;
    .card-body{
      padding: 0.2rem;
      background: $blue;
      border-radius: 0rem;
      margin-top: -.1rem;
      height: 5rem;
    }
    .card-title{
      line-height: 16px;
      color: #FFF;
      font-size: 14px;
      max-height: 50px;
      //white-space: nowrap;
      margin-right: 1rem;
      margin-left: .5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card-text{
      display: none;
    }

    .video-cta{
      bottom: 7rem;
      width: 25%;
    }
  }
  .card-title{
    min-height: 50%;
    align-items: center;
    display: flex;
    text-decoration: none !important;
    color: $yellow;
    font-family: 'Archivo Black', serif;
    font-size: 20.91px;
    font-weight: 500;
    line-height: 22.75px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .video-cta{
    height: 40px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 7rem;
    text-align: center;
    line-height: 35px;

    svg{
      width: 100%;
    }
  }

  //&.grid{
  //  height: 137px !important;
  //}

  &.list{
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: $lightBlue;
    height: 137px !important;

    .mediaWrapper{
      position: relative;

      .video-cta{
        bottom: 2rem !important;
      }
    }

    .card-img-top{
      height: 130px;
      width: 130px;
      object-fit: cover;
    }

    .card-body{
      background: $lightBlue;
    }

    .card-title{
      color: #000;
      white-space: break-spaces;
    }


  }
}
