@import "../../common";

.scoreCard {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: 0 0 2px #9ba0a7;
  overflow: hidden;

  .tab-content{
    min-height: 308px;
  }

  &.round{

    text-align: left;
    .eventLink{
      width: 50%;
      display: inline-block;
      position: relative;
      @media (max-width: 1024px) {
        width: 100%;
        display: block;
      }
    }

    box-shadow: none;
    .scoreTabs{
      border-radius: 5rem;

      .nav-link {
        border-radius: 5rem;
      }
    }
  }

  .scoreTabs {
    border-color: transparent;
    background: $lightBlue;
    padding-right: 0;

    .nav-link {
      border: 0 transparent;
      color: $link;
      font-size: 14px;

      &:hover, &:focus {
        border: 0 transparent;
      }

      &.active {
        border: 0px;
        border-bottom: solid 1px $border-color;
        background: $primary;
        color: #FFF;
        font-weight: 700;
      }
    }
  }

  .eventHr {
    margin: 0 1rem !important;
    color: $primary !important;
  }
}


.scoreSubTitle{
  font-family: Archivo, serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 10.88px;
  letter-spacing: 0.02em;
  text-align: center;
}

.textShimmer{
  margin-bottom: 0 !important;
}

svg.rotate{
  animation-name: loadingRotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes loadingRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
