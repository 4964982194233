@import "../../common";

.head2head{
  border-color: $border-color;
  border-bottom: 0 !important;

  table{
    padding: 2rem !important;
    td{
      background: transparent !important;
      color: #000;
      height: 40px;
    }
  }
  .player{
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }

  .player_profile{
    border-radius: 50%;
    background: $lightBlue;
    border: 1px solid $lightBlue;

    img{
      width: 130px;
      height: 130px;
      object-fit: contain;
    }
  }

  .players_profile{
    position: relative;
    z-index: 10;
  }

  .players_info{
    background: $primary;
    height: 70px;
    margin-top: -25px;
    z-index: 11;
    position: relative;

    .player_info{
      font-family: Archivo Black, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 15.23px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .h2hdetails{
    background: #121fff;
    color: #FFF;
    font-size: 20px;
  }

  .stats{
    font-size: 14px;
  }

}
