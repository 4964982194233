.modal{
  font-family: Archivo, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 17.41px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #000;

  .modal-header{
    border-bottom: none;
  }
  .btn-close{
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
  }
  .modal-footer{
    border-top: none;
  }
}